<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <template v-slot:options>
        <FormButtonGroup align="end" spaced>
          <FormButton text="Novo calendário" type="primario" @click="onNew" />
        </FormButtonGroup>
      </template>
      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        sortBy="nome_calendario"
        :sortDesc="false"
        @click="onClick"
        ref="datatable"
        search
      >
        <template v-slot:idcalendario="{ row }">
          <FormButtonMenu
            icon
            :options="tableOptions"
            :loading="loadingRow[row.idcalendarioentrega]"
            @click="op => selectOption(row, op)"
          >
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
      </DataTable>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import Panel from '@/components/Panel'
import FormButton from '@/components/form/FormButton'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import FormButtonGroup from '@/components/form/FormButtonGroup'
import MarketingModules from '@/components/MarketingModules'
import { mapGetters } from 'vuex'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import { CALENDARIO_REMOVE } from '@/store/actions/calendario'

export default {
  name: 'CalendarioTable',
  components: {
    DataTable,
    Panel,
    FormButton,
    FormButtonMenu,
    FormButtonGroup,
    MarketingModules
  },
  data: () => ({
    headers: [
      { text: 'Nome', value: 'nome_calendario' },
      { text: 'Data Criação', value: 'data_criacao', align: 'center' },
      {
        text: 'Quantidade de Datas Exibidas',
        value: 'qtd_datas_exibidas',
        align: 'center'
      },
      {
        text: 'Horário Máx. Atendimento',
        value: 'horario_max_atendimento',
        align: 'center'
      },
      { text: 'Opções', value: 'idcalendario', clickable: false }
    ],
    tableOptions: [{ text: 'Deletar', value: 'delete' }],
    loadingRow: {},
    modulosForm: [
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP
    ]
  }),
  computed: {
    ...mapGetters(['getFornecedorId', 'moduloFornecedorAtivo']),
    endpoint() {
      return `/api/v4/calendario/listForTable/${this.getFornecedorId}`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  created() {},
  methods: {
    onClick(item) {
      this.$router.push(`calendarios/${item.idcalendarioentrega}`)
    },
    onNew() {
      this.$router.push('calendarios/novo')
    },
    onClose() {
      this.$refs.datatable.reload()
      this.loadingRow = {}
    },
    selectOption(item, option) {
      switch (option.value) {
        case 'delete':
          return this.onDelete(item)
      }
    },
    onDelete(item) {
      const options = {
        text: 'Essa ação é irreversível.',
        title: `Você confirma a exclusão do calendário '${item.nome_calendario}'?`
      }
      this.confirmSwal(options, () => this.delete(item))
    },
    delete(item) {
      this.loadingRow = {
        ...this.loadingRow,
        [item.idcalendarioentrega]: true
      }
      this.$store
        .dispatch(CALENDARIO_REMOVE, item.idcalendarioentrega)
        .then(() => {
          this.$vueOnToast.pop('success', 'Calendário removido com sucesso')
          this.$refs.datatable.reload()
          this.loadingRow = {
            ...this.loadingRow,
            [item.idcalendarioentrega]: false
          }
        })
        .catch(() => {
          const erro =
            'Falha ao remover o calendário. Verifique se ele não está em uso por uma forma de entrega.'
          this.$vueOnToast.pop('error', erro)
          this.loadingRow = {
            ...this.loadingRow,
            [item.idcalendarioentrega]: false
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
